import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState } from "react";
import { globalAppConfig } from "../../config";
import "./AddTeamGeocoder.css";

// Set Mapbox API Access Token.
mapboxgl.accessToken = globalAppConfig.MAP_BOX_ACCESS_TOKEN;

interface Props {
    updateAddress: (team: any) => void;
    placeholder?: string;
}

const AddTeamGeocoder: React.FC<Props> = ({ updateAddress, placeholder }) => {
    const geocoderContainerRef = useRef<HTMLDivElement>(null);
    const [lat, setLat] = useState<number | null>(null);
    const [lon, setLon] = useState<number | null>(null);

    mapboxgl.accessToken = globalAppConfig.MAP_BOX_ACCESS_TOKEN;

    useEffect(() => {
        // Initialize map when component mounts
        addGeocoderToContainer();
    }, []);

    const removeGeocoderFromContainer = () => {
        if (document.getElementById("geocoder-container")) {
            geocoder.remove();
        }
    };

    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        container: geocoderContainerRef.current!,
        placeholder: placeholder || "Search for your team's address",
        types: "address",
    });

    geocoder.on("result", (e) => {
        const data = e.result;
        let team = {};

        function getInfo(value: string) {
            let info = null;
            const context = data.context;
            context.map((object: any) => {
                if (object.id.startsWith(value)) {
                    info = object.text;
                }
            });
            return info;
        }

        function getAddress() {
            let address = "";
            if (data.place_type[0] === "address") {
                address = data.address + " " + data.text;
            } else {
                address = data.properties.address;
            }
            return address;
        }

        team = {
            address_1: getAddress(),
            address_2: null,
            city: getInfo("place"),
            state: getInfo("region"),
            zip_code: getInfo("postcode"),
            lat: data.center[1],
            lon: data.center[0],
        };

        updateAddress({
            team,
        });

        setLat(data.center[1]);
        setLon(data.center[0]);
    });

    const addGeocoderToContainer = async () => {
        // Hacky hackness to make sure the geocoder is only added once
        if (document.getElementById("geocoder-container").children.length == 0) {
            geocoder.addTo("#geocoder-container");
        }
    };

    const openMapLink = () => {
        window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(lat + "," + lon), "_blank");
    };

    return (
        <div>
            <div ref={geocoderContainerRef} id="geocoder-container"></div>
            {lat && lon && (
                <div className="w-full mt-[-20px] ml-[8px]">
                    <a className="text-plot-orange text-right underline hover:plot-orange" onClick={openMapLink}>
                        {lat}, {lon}
                    </a>
                </div>
            )}
        </div>
    );
};

export default AddTeamGeocoder;
